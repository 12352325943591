import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getUserPreferences,
  syncUserPreferences,
} from '@kiosk/api/UserPreferencesApi';
import _ from 'lodash';

import { status } from '@kiosk/redux/constants';

const initialState = {
  userPreferences: null,
  preferencesAreLoaded: false,
  status: status.IDLE,
  error: null,
};

export const fetchUserPreferences = createAsyncThunk(
  'userPreferences/fetchUserPreferences',
  async (uid) => {
    const res = await getUserPreferences(uid);
    return res;
  }
);

/**
 * @param {string} uid the uid of the current user
 * @param {Object} newUserPreferences the updated user pref object that is to be set
 * @param {string} key the key for the preference
 * @param {any} preference the value of the preference to be stored
 * @param {string} view the view this preference applies to (ie. 'RecordList'). Note: If view is specified to be 'app', the preference will be app level
 * @param {string} module the module the preference applies to within the given view (ie. 'Cases'). This arg is default to null unless specifiec
 *
 */
export const setNewUserPreferences = createAsyncThunk(
  'userPreferences/setNewUserPreferences',
  async ({
    uid,
    prevUserPreferences,
    key,
    preference,
    view,
    module = null,
  }) => {
    if (prevUserPreferences == null) {
      return prevUserPreferences;
    }

    let newUserPreferences = _.cloneDeep(prevUserPreferences);

    // Ensure that the view and module paths are created in the preference object first
    if (newUserPreferences[view] === undefined) {
      newUserPreferences[view] = {};
      if (module) {
        newUserPreferences[view][module] = {};
      }
    } else if (module && newUserPreferences[view][module] === undefined) {
      newUserPreferences[view][module] = {};
    }

    // If module is defined, then add the preferences to module specific preference
    if (module) {
      newUserPreferences[view][module][key] = preference;
      // else add it to view specific preferences (view may be 'app' if app wide preferences)
    } else {
      newUserPreferences[view][key] = preference;
    }

    const res = await syncUserPreferences(uid, newUserPreferences);

    return res;
  }
);

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserPreferences.pending, (state, action) => {
        state.status = status.PENDING;
      })
      .addCase(fetchUserPreferences.fulfilled, (state, action) => {
        state.status = status.FULFILLED;
        state.preferencesAreLoaded = true;
        state.userPreferences = action.payload.Preferences;
      })
      .addCase(fetchUserPreferences.rejected, (state, action) => {
        state.preferencesAreLoaded = false;
        state.error = action.error.message;
        state.status = status.FAILED;
      })
      .addCase(setNewUserPreferences.pending, (state, action) => {
        state.status = status.PENDING;
      })
      .addCase(setNewUserPreferences.fulfilled, (state, action) => {
        state.status = status.FULFILLED;
        state.userPreferences = action.payload.Preferences;
      })
      .addCase(setNewUserPreferences.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = status.FAILED;
      });
  },
});

export const selectUserPreferences = (state) =>
  state.userPreferences.userPreferences;
export const selectUserPreferencesStatus = (state) =>
  state.userPreferences.status;
export const selectUserPreferencesAreLoaded = (state) =>
  state.userPreferences.preferencesAreLoaded;
export const selectUserPreferencesLanguage = (state) =>
  state.userPreferences.userPreferences;

export const { updateLanguage } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
