import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Avatar, Box, Fab, Slide, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChatInterface from '@kiosk/components/ChatInterface';

/**
 * Component that represents the static chat button that will sit as an overlay on bottom right of the users screen. Controls starting and collapsing/expanding the chat interface from AWS
 */
const ChatButton = ({ faviconNotificationCount, setFaviconNotificationCount }) => {
  const containerRef = useRef(null);
  const [isChatInitiated, setIsChatInitiated] = useState(false);
  const [isChatEnded, setIsChatEnded] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [childKey, setChildKey] = useState(1);
  const theme = useTheme();

  /**
   * Handles the click event for the chat button
   */
  const onClickHandler = () => {
    if (chatStarted) {
      setIsCollapsed((prev) => !prev);
    } else {
      setChatStarted(true);
    }

    if (isChatEnded) {
      // Destroy ChatInterface component in order to reset the connection for next time when user clicks the chat button.
      setChildKey(prev => prev + 1);
      setIsChatEnded(false);
      // Force second click on the chat button.
      // Because React handles the state change in async mode, the update happens after second click.
      document.getElementById('chat-button').click();
    }
  };

  // TODO: Add logic to only render this button if sugarlive chat is active

  return createPortal(
    <>
      <Box
        id='chat-transition-container'
        sx={{
          overflow: 'hidden',
          display: isChatInitiated && !isChatEnded && !isCollapsed ? 'block' : 'none'
        }}
        ref={containerRef}>
        <Slide
          direction='up'
          in={isChatInitiated && !isChatEnded && !isCollapsed}
          container={containerRef.current}
          timeout={600}>
          <Box>
            <Fade in={isChatInitiated && !isChatEnded && !isCollapsed} timeout={1000}>
              <Box id='chat-interface'>
                {chatStarted && (
                  <ChatInterface
                    key={childKey}
                    setIsChatInitiated={setIsChatInitiated}
                    setIsChatEnded={setIsChatEnded}
                    setFaviconNotificationCount={setFaviconNotificationCount}
                  />
                )}
              </Box>
            </Fade>
          </Box>
        </Slide>
      </Box>

      <Box sx={{ position: 'fixed', right: 20, bottom: 20 }}>
        <Fab
          onClick={onClickHandler}
          id='chat-button'
          size='medium'
          color='primary'
          aria-label='chat-button'>
          {(!isChatInitiated || isChatEnded || (isChatInitiated && isCollapsed)) && (
            <>
              {faviconNotificationCount !== 0 &&
                <Avatar
                  sx={{
                    position: 'absolute',
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.common.white,
                    width: 18,
                    height: 18,
                    top: -8,
                    right: -8,
                    fontSize: '14px'
                  }}
                >
                  {faviconNotificationCount}
                </Avatar>
              }
              <ChatBubbleOutlineIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </>
          )}
          {isChatInitiated && !isChatEnded && !isCollapsed && <KeyboardArrowDownIcon />}
        </Fab>
      </Box>
    </>,
    document.getElementById('aws-chat-widget')
  );
};

export default ChatButton;
