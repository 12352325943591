/**
 * Variables used for building URLs to make requests to our CRM API
 */
export const CRM_API_V1_BASE = 'kiosk/crm';
export const CRM_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our DocStore API
 */
export const DOCSTORE_API_V1_BASE = 'kiosk/docstore';
export const DOCSTORE_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our App Configuration API
 */
export const APP_CONFIG_API_V1_BASE = 'kiosk/appconfiguration';
export const APP_CONFIG_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our Auth0 Configuration API
 */
export const AUTH0_CONFIG_API_V1_BASE = 'kiosk/auth0';
export const AUTH0_CONFIG_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our User Preferences API
 */
export const USER_PREFERENCES_API_V1_BASE = 'kiosk/userpreferences';
export const USER_PREFERENCES_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our User Management API
 */
export const USER_MANAGEMENT_API_V1_BASE = 'kiosk/user';
export const USER_MANAGEMENT_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our Tenant API
 */
export const TENANT_API_V1_BASE = 'kiosk/tenant';
export const TENANT_API_V1_VERSION = 'v1';

/**
 * Variables used for building URLs to make requests to our Language API
 */
export const LANGUAGE_API_V1_BASE = 'kiosk/language';
export const LANGUAGE_API_V1_VERSION = 'v1';
