/**
 * Grabs the host and protocol from the users window, and returns an encoded route.
 * @param {string} baseRoutePath
 * @returns string
 */
const getRequestUrl = (baseRoutePath) => {
  const redirectPath = new URLSearchParams(window.location.search).get(
    'redirect-path'
  );
  return encodeURI(
    `${baseRoutePath}?request-url=${window.location.protocol}//${window.location.host}&redirect-path=${redirectPath}`
  );
};

export default getRequestUrl;
