import { Breadcrumbs, Link, Chip, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import localization from '@kiosk/i18n/localization';
import { useSelector } from 'react-redux';
import { selectBreadcrumbNameMap } from '@kiosk/redux/slices/breadcrumbs/breadcrumbsSlice';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {
    fontSize: theme.typography.body2.fontSize,
  };
});

/**
 * Breadcrumb component for the Kiosk applicatoin
 * NOTE: This component only makes sense and should only be used inside of the BreadcrumbContext.
 * @param {object} breadcrumbNameMap name mapping for breadcrumb paths
 */
const KioskBreadcrumbs = ({ children }) => {
  const breadcrumbNameMap = useSelector(selectBreadcrumbNameMap);
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const shouldHideBreadcrumbs =
    breadcrumbNameMap[location.pathname]?.isBase ||
    !Object.keys(breadcrumbNameMap).includes(location.pathname) ||
    Object.keys(breadcrumbNameMap).length === 0;

  return shouldHideBreadcrumbs ? (
    <>{children}</>
  ) : (
    <>
      <Container
        disableGutters
        maxWidth={breadcrumbNameMap[location.pathname]?.containerWidth}>
        <Breadcrumbs
          id='breadcrumbs'
          aria-label='breadcrumbs'
          sx={{
            mb: 2,
            mt: 1,
            '.MuiBreadcrumbs-separator': {
              color: 'divider',
            },
            '.MuiBreadcrumbs-ol': {
              flexWrap: 'nowrap',
            },
            '.MuiBreadcrumbs-li:last-of-type': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }
          }}>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last
              ? breadcrumbNameMap[to] && (
                  <Link underline='none' key={to}>
                    <StyledBreadcrumb
                      data-testid='breadcrumb-nonclickable'
                      size='small'
                      label={localization.get(
                        breadcrumbNameMap[to]?.label,
                        breadcrumbNameMap[to]?.module
                      )}
                    />
                  </Link>
                )
              : breadcrumbNameMap[to] && (
                  <Link
                    to={to}
                    component={RouterLink}
                    underline='none'
                    key={to}>
                    <StyledBreadcrumb
                      data-testid='breadcrumb-clickable'
                      size='small'
                      label={localization.get(
                        breadcrumbNameMap[to]?.label,
                        breadcrumbNameMap[to]?.module
                      )}
                      clickable
                    />
                  </Link>
                );
          })}
        </Breadcrumbs>
      </Container>
      {children}
    </>
  );
};

export default KioskBreadcrumbs;
