import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@kiosk/redux/slices/auth/authSlice';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isUserAuthenticated = useSelector(selectIsAuthenticated);

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={`/login?redirect-path=${window.location.pathname}`}
        replace
      />
    );
  }
  return children;
};

export default ProtectedRoute;
