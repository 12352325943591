import localization from '@kiosk/i18n/localization';

/**
 * Helper component for using localized strings.
 * @param {string} name name of the language key
 * @param {string} module (optional) module name to look in first. If not found in the module's scope,
 *                        falls back to the global scope.
 * @param {Object} templateVals (optional) an object containing the values to be used in the template.
 *
 * Insert a string with the language key 'LBL_KIOSK_TITLE':
 *   <LocalizedString name='LBL_KIOSK_TITLE' />
 * Renders as:
 *   Kiosk
 *
 * Insert a string with the language key 'LBL_KIOSK_MODULE_NAME_SINGULAR' for the module 'Cases':
 *   <LocalizedString name='LBL_KIOSK_MODULE_NAME_SINGULAR' module='Cases' />
 * Renders as:
 *   Case
 */
const LocalizedString = ({ name, module, templateVals = null }) => {
  return templateVals
    ? localization.getAndApplyTemplate(name, module, templateVals)
    : localization.get(name, module);
};

/**
 * Helper component for using localized dropdowns.
 * @param {string} name name of the language key
 * @param {string} dropdownKey dropdown key
 *
 * Insert a string with the dropdown key 'assigned' within the dropdown 'kiosk_case_status_dom'
 *   <LocalizedDropdownString name='kiosk_case_status_dom' dropdownKey='assigned' />
 * Renders as:
 *   Assigned
 */
const LocalizedDropdownString = ({ name, dropdownKey }) => {
  return localization.getAppListValue(name, dropdownKey);
};

export { LocalizedString, LocalizedDropdownString };
