/**
 * Determines whether a request should be sent to the Tenant API for lookup
 * @returns boolean
 */
const skipStandardAuthFlow = () => {
  return (
    window.location.search.includes('invitation=') ||
    window.location.search.includes('iss=')
  );
};

export default skipStandardAuthFlow;
