import { Icon } from '@mui/material';

/**
 * A modified MUI-based component for rendering SugarIcons.
 * @param name Name of the icons in the font
 * @param size Abbreviation for the using the sizing helper class (lg, 16, 32, etc)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SugarIcon = ({ name, size = '', ...props }) => {
  return (
    <Icon
      baseClassName='sicon'
      className={`sicon-${name}${size ? ` sicon-${size}` : ''}`}
      fontSize=''
      {...props}
    />
  );
};

export default SugarIcon;
