import { ROLES_TO_DISPLAY_NAME_MAP } from '@kiosk/config/Roles';

/**
 * Check if any of the provided roles match the allowed roles.
 *
 * @param roles roles that user has
 * @param allowedRoles roles that the user must have to be allowed access
 * @returns {boolean}
 */
export const isAllowedByRole = (roles, allowedRoles) => {
  let isAllowed = false;
  if (!Array.isArray(roles)) {
    return isAllowed;
  }
  roles.forEach((role) => {
    if (allowedRoles.includes(role)) {
      isAllowed = true;
    }
  });

  return isAllowed;
};

/**
 * Returns an array of role names based on the given array of roles as defined by auth0.
 * @param {string[]} roles
 * @returns {string[]} roles as their display name
 */
export const getRoleNames = (roles) =>
  roles.map((r) => ROLES_TO_DISPLAY_NAME_MAP[r]);
