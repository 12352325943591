import { useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import { updateUserDetails } from '@kiosk/api/UserManagementApi';
import UserProfileField from '@kiosk/components/UserProfile/UserProfileField';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import { useSnackbar } from 'notistack';
import localization from '@kiosk/i18n/localization';
import CustomNotistackSnackbar from '@kiosk/components/CustomNotistackSnackbar';
import { useDispatch } from 'react-redux';
import { updateCrmUserDetails } from '@kiosk/redux/slices/auth/authSlice';

/**
 * Form component that houses all FirstTimeLoginModal fields
 */
const FirstTimeLoginModalForm = ({
  currentUser,
  firstTimeLoginModalFields,
  setOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const dispatchRedux = useDispatch();

  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      let updatedUser = await updateUserDetails(
        currentUser?.user_id,
        data,
      );
      if (updatedUser) {
        // Cache crmUserDetails for later use
        dispatchRedux(updateCrmUserDetails(updatedUser));
        // Close modal
        setOpen(false);
      } else {
        // Trigger failure
        enqueueSnackbar(localization.get('LBL_KIOSK_PROFILE_UPDATE_ERROR'), {
          content: (key, message) => (
            <CustomNotistackSnackbar
              id={key}
              message={message}
              variant={'error'}
            />
          ),
        });
      }
      setSubmitLoading(false);
    } catch (err) {
      setSubmitLoading(false);
      enqueueSnackbar(localization.get('LBL_KIOSK_500_TITLE'), {
        content: (key, message) => (
          <CustomNotistackSnackbar
            id={key}
            message={message}
            variant={'error'}
          />
        ),
      });
    }
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      id='first-time-login-modal-form'
      data-testid='first-time-login-modal-form'>
      <Grid container direction='row' columns={4} rowSpacing={1}>
        {firstTimeLoginModalFields.map((f) => (
          <Controller
            key={f.field_name}
            name={f.field_name}
            control={control}
            rules={f.rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UserProfileField
                key={`FirstTimeLoginModalField-${f.field_name}`}
                field={f}
                hidden={f.hidden}
                onChange={onChange}
                error={!!error}
                value={value ?? ''}
                isEditMode={true}
                helperText={error ? error.message : ''}
              />
            )}
          />
        ))}
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ marginTop: '1.5rem' }}
        justifyContent='flex-end'>
        <LoadingButton
          id='first-time-login-modal-save-button'
          data-testid='first-time-login-modal-save-button'
          disabled={Object.keys(errors).length > 0}
          sx={{
            '.MuiLoadingButton-loadingIndicator': {
              right: 10,
            },
            '.MuiButton-endIcon': {
              display: submitLoading ? 'inherit' : 'none',
            },
          }}
          loading={submitLoading}
          loadingIndicator={
            <CircularProgress sx={{ color: 'action.disabled' }} size={16} />
          }
          loadingPosition='end'
          endIcon={
            <SaveIcon
              sx={{
                display: submitLoading ? 'block' : 'none',
                visibility: 'hidden',
              }}
            />
          }
          variant='contained'
          type='submit'>
          <LocalizedString name='LBL_KIOSK_SAVE' />
        </LoadingButton>
      </Grid>
    </Box>
  );
};

export default FirstTimeLoginModalForm;
