import 'amazon-connect-chatjs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import '@kiosk/lib/amazon-connect-chat-interface/amazon-connect-chat-interface.js';
import { selectAppConfig } from '@kiosk/redux/slices/appConfig/appConfigSlice';
import { selectUser} from '@kiosk/redux/slices/auth/authSlice';

const connect = window.connect;

const AGENT_ROLE = 'AGENT';
const CUSTOMER_ROLE = 'CUSTOMER';
const EVENT_TYPE_MESSAGE = 'MESSAGE';

/**
 * Implements the base aws chat interface.
 * See https://github.com/amazon-connect/amazon-connect-chat-interface for more information and for potential customization in the future
 */
const ChatInterface = ({ setIsChatInitiated, setIsChatEnded, setFaviconNotificationCount }) => {
  const appConfig = useSelector(selectAppConfig);
  const REGION = appConfig?.SugarLive?.awsConnectRegion || '';
  const NAME = appConfig?.SugarLive?.awsConnectInstanceName || '';
  const API_GATEWAY_ENDPOINT = appConfig?.SugarLive?.awsConnectApiGatewayUrl || '';
  const CONTACT_FLOW_ID = appConfig?.SugarLive?.awsConnectContactFlowId || '';
  const INSTANCE_ID = appConfig?.SugarLive?.awsConnectInstanceId || '';

  const user = useSelector(selectUser);
  const USER_NAME = user.name || '';
  // Admin users will not have a contact_id.
  const CONTACT_ID = user.contact_id || '0';
  const USER_EMAIL = user.email || '';
  const ACCOUNT_ID = user.account_id || '';

  const handleCloseChatWindow = () => {
    document
      .getElementsByClassName('sc-gzVnrw fgSGc sc-dNLxif iuWsgu')
      .item(0)
      ?.addEventListener('click', (e) => {
        e.preventDefault();
        setIsChatEnded(true);
        setIsChatInitiated(false);
      });
    setFaviconNotificationCount(0);
  }

  useEffect(() => {
    connect.ChatSession.setGlobalConfig({
      region: REGION,
    });

    // TODO: Add other initiator values for customization when we have the configs
    connect.ChatInterface.init({
      containerId: 'chat-interface',
    });

    connect.ChatInterface.initiateChat(
      {
        region: REGION,
        name: NAME,
        apiGatewayEndpoint: API_GATEWAY_ENDPOINT,
        contactFlowId: CONTACT_FLOW_ID,
        instanceId: INSTANCE_ID,
        contactAttributes: JSON.stringify({
          sugarContactName: USER_NAME,
          sugarContactId: CONTACT_ID,
          sugarContactEmail: USER_EMAIL,
          sugarAccountId: ACCOUNT_ID,
        }),
      },
      initiateChatSuccess,
      initiateChatFailure,
    );

    setIsChatInitiated(true);
    // eslint-disable-next-line
  }, [
    API_GATEWAY_ENDPOINT,
    CONTACT_FLOW_ID,
    INSTANCE_ID,
    NAME,
    REGION,
    setIsChatInitiated,
  ]);

  const initiateChatSuccess = (chatSession) => {
    console.log('----- chat initiated -----');

    chatSession.onIncoming((data) => {
      // Increase the notification alert count if the income message is from an agent.
      if (
        data.ParticipantRole === AGENT_ROLE &&
        data.Type === EVENT_TYPE_MESSAGE
      ) {
        setFaviconNotificationCount((prev) => prev + 1);
      }
    });

    chatSession.onOutgoing((data) => {
      // Reset the notification alert count if the outcome message is from a customer.
      if (
        data.ParticipantRole === CUSTOMER_ROLE &&
        data.Type === EVENT_TYPE_MESSAGE
      ) {
        setFaviconNotificationCount(0);
      }
    });

    chatSession.onChatDisconnected(() => {
      handleCloseChatWindow();
    });

    // Change the default background color of the transcript from transparent to white.
    document.getElementsByClassName("connect-customer-interface").item(0).style.backgroundColor = '#ffffff';

    handleCloseChatWindow();
  };

  const initiateChatFailure = () => {
    console.log('----- chat failed to initiate -----');
  };

  // This componet returns nothing, but renders the chat interface in the #chat-interface div
  // This is a unique component since AWS doesnt provide an explicit React component for their chat interface
  return <></>;
};

export default ChatInterface;
