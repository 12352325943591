import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectIsAuthenticated,
  selectUser,
} from '@kiosk/redux/slices/auth/authSlice';

import HttpErrorPage from '@kiosk/pages/HttpErrorFallback/HttpErrorPage';
import { isAllowedByRole } from '@kiosk/utils/roles';

const RestrictedRoute = ({ children, allowedRoles }) => {
  const isUserAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={`/login?redirect-path=${window.location.pathname}`}
        replace
      />
    );
  } else if (isUserAuthenticated && isAllowedByRole(user.user_roles, allowedRoles)) {
    return children;
  } else {
    return <HttpErrorPage code={403} />;
  }
};

export default RestrictedRoute;
