import { useRef } from 'react';
import { useNonInitialEffect } from '@kiosk/hooks/useNonInitialEffect';
import { useLocation } from 'react-router-dom';
import HttpErrorPage from '@kiosk/pages/HttpErrorFallback/HttpErrorPage';
import { authRedirects } from '@kiosk/redux/slices/auth/authSlice';

/**
 * HttpErrorFallback component renders the custom error page that will trigger whenever a JS error is thrown
 * @param {Object} error the JavaScript error that is thrown
 * @param {Function} resetErrorBoundary the reset function that is passed automatically with react-error-boundary
 * library
 */
const HttpErrorFallback = ({ error, resetErrorBoundary }) => {
  // This allows the error to listen for the user attempting to navigate to a new page and will clear the error state in
  // memory and allow the location change to occur without issue.
  const location = useLocation();
  const initialPath = useRef(location.pathname);

  useNonInitialEffect(() => {
    // Safeguard put in to satisfy React.StrictMode mount and unmount cycle
    if (location.pathname === initialPath.current) return;
    resetErrorBoundary();
  }, [location]);

  const errorCode = error?.cause?.response?.status;
  const errorMessage = error?.cause?.response?.data?.message;

  // Return early if the cookie is expired
  const hasCookieExpired =
    errorCode === 401 && errorMessage === 'cookie has expired';
  if (hasCookieExpired) {
    return authRedirects.logout();
  }

  return (
    <HttpErrorPage code={errorCode} resetErrorBoundary={resetErrorBoundary} />
  );
};

/**
 * onErrorHandler is a helper function that is used to log useful information or take a particular actions during an
 * HTTP error.
 * @param {object} err the error being passed
 * @param {object} info object containing the component stack as a string
 */
export const onHttpErrorHandler = (err, info) => {
  // TODO: Add more robust logging solution here in the future
  console.error('Error: ', err);
};

export default HttpErrorFallback;
