// This const defines the current supported default structure of our App Config object based on what configuration options we currently support. All object level attributes must be defined. Value level attributes will be created and filled in at time of configuration.
const AppConfig = {
  Pages: {
    Home: {
      Backdrop: {},
    },
  },
  Theme: {
    Favicon: {},
    Customizations: {},
  },
};

export default AppConfig;
