/**
 * API functions related to language service
 */

import {
  LANGUAGE_API_V1_BASE,
  LANGUAGE_API_V1_VERSION,
} from '@kiosk/config/ApiConfig';
import { kFetch } from './ApiHelper';

/**
 * Returns the language strings object for a specific language code.
 */
export const getOverrideLanguageStrings = async (langCode) => {
  try {
    const res = await kFetch.get(
      `/${LANGUAGE_API_V1_BASE}/${LANGUAGE_API_V1_VERSION}/${langCode}`
    );

    return res?.data || null;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};
