import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import localization from '@kiosk/i18n/localization';
import { supportedModules } from '@kiosk/metadata/base/baseMetadata';
import { languages } from '@kiosk/config/language';

/**
 *
 * @param {Object} sx - Custom styles to use
 * @returns A non-editable TextField component for display purposes
 */
const NonEditableField = ({ name, options, type, value }) => {
  let convertedValue = '';
  switch (type) {
    case 'dropdown':
      // Special case for language dropdown which is not translated
      if (name === 'language') {
        convertedValue = languages[value] ?? '';
      } else {
        convertedValue = localization.getAppListValue(options, value);
      }
      break;
    default:
      convertedValue = value;
  }

  return (
    <Box>
      <Typography
        aria-labelledby={`${name}-field-label`}
        data-testid={`${name}-field`}
        id={`${name}-field`}
        sx={{
          lineHeight: '2.5rem',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        value={value}>
        {convertedValue}
      </Typography>
    </Box>
  );
};

/**
 * Component to render the proper field based on userProfileMetadata entry for a particular field
 * @param {String} type - Type of field to render
 * @returns Component to render
 */
const EditableField = ({
  disabled,
  error,
  helperText,
  name,
  onChange,
  options,
  readonly,
  type,
  value,
}) => {
  let profileField = '';

  switch (type) {
    case 'dropdown':
      // menuItems can be defined as a app list string or an already translated object
      let domList;
      // if the menu items is a string representing an app list object that needs retrieved
      if (typeof options === 'string') {
        domList = localization.getAppListObject(options);
        // if the menu items is an object representing the already translated object for menu items
      } else {
        domList = options;
      }

      profileField = (
        <Select
          data-testid={`${name}-${type}-field`}
          value={value ?? ''}
          inputProps={{
            id: `${name}-${type}-field`,
          }}
          disabled={disabled}
          displayEmpty
          error={error}
          fullWidth
          id={`${name}-${type}-field`}
          onChange={onChange}
          sx={{ lineHeight: '2.5rem', m: 0 }}>
          {Object.entries(domList).map((item) => (
            <MenuItem key={item[0]} value={item[0]}>
              {item[1]}
            </MenuItem>
          ))}
        </Select>
      );
      break;
    default:
      profileField = (
        <TextField
          aria-labelledby={`${name}-field-label`}
          data-testid={`${name}-${type}-field`}
          disabled={readonly}
          error={error}
          fullWidth
          helperText={helperText}
          FormHelperTextProps={{
            sx: { ml: 0 },
          }}
          id={`${name}-${type}-field`}
          onChange={(event) => {
            onChange(event);
          }}
          size='small'
          sx={{ lineHeight: '2.5rem', m: 0 }}
          value={value || ''}
          variant='outlined'
          inputProps={{ maxLength: options?.maxLength }}
        />
      );
  }

  return profileField;
};

/**
 * Reusable component for rendering User data fields.
 * @param {String} field_name - Named User property for the field
 * @param {String} field_label - Localized string label to be used to identify the field name
 * @param {Boolean} readonly - Field is readonly
 * @param {*} value - The current value of the field
 * @param {Boolean} isEditMode - Hook to determine which version of the field should be rendered
 * @param {Object} control - The react-hook-form control to tie things together
 * @returns Component to render to User Profile
 */
const UserProfileField = ({
  field: { field_label: label, field_name: name, options, readonly, type },
  hidden = false,
  onChange,
  error,
  value,
  isEditMode,
  helperText,
}) => {
  const theme = useTheme();
  const isMobileScreenSize = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid item xs={12} hidden={hidden}>
      <FormControl
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: isMobileScreenSize ? 'column' : 'row',
        }}>
        <Grid
          item
          xs={isMobileScreenSize ? 4 : 2}
          sx={{ lineHeight: '2.5rem' }}>
          <FormLabel
            component={isEditMode && !readonly ? 'label' : 'div'}
            id={`${name}-field-label`}
            data-testid={`${name}-field-label`}
            htmlFor={`${name}-${type}-field`}
            sx={{ lineHeight: '2.5rem' }}>
            <LocalizedString
              name={label}
              module={supportedModules.userManagement}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={isMobileScreenSize ? 8 : 6} sx={{ maxWidth: '100%' }}>
          <>
            {isEditMode && !readonly ? (
              <>
                <EditableField
                  disabled={readonly}
                  error={error}
                  helperText={helperText}
                  name={name}
                  onChange={onChange}
                  options={options}
                  size='small'
                  type={type}
                  value={value ?? ''}
                  variant='outlined'
                />
                {/* {error && 'Please fix this'} */}
              </>
            ) : (
              /* Match MUI TextField height, using line-height to prevent any shifting fields while toggling edit mode */
              <NonEditableField
                name={name}
                options={options}
                type={type}
                value={value ?? ''}
              />
            )}
          </>
        </Grid>
      </FormControl>
    </Grid>
  );
};

export default UserProfileField;
