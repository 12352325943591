import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAuthenticated,
  selectAuthStatus,
  selectUser,
  getAuthUser,
} from '@kiosk/redux/slices/auth/authSlice';
import {
  selectTenantFound,
  selectAppConfigStatus,
  getAppConfig,
  selectAppConfigIgnorePreviewMode,
} from '@kiosk/redux/slices/appConfig/appConfigSlice';
import {
  fetchUserPreferences,
  selectUserPreferences,
  selectUserPreferencesStatus,
} from '@kiosk/redux/slices/userPreferences/userPreferencesSlice';
import { status } from '@kiosk/redux/constants';
import './styles/App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Footer from '@kiosk/components/Footer';
import localization from '@kiosk/i18n/localization';
import KioskRouter from '@kiosk/router/KioskRouter';
import FirstTimeLoginModal from '@kiosk/components/FirstTimeLoginModal';
import Navbar from '@kiosk/components/Navbar';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { theme } from '@kiosk/theme';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import Favicon from 'react-favicon';
import HttpErrorFallback, {
  onHttpErrorHandler,
} from '@kiosk/pages/HttpErrorFallback';
import HttpErrorPage from '@kiosk/pages/HttpErrorFallback/HttpErrorPage';
import KioskBreadcrumbs from '@kiosk/components/KioskBreadcrumbs';
import { SnackbarProvider } from 'notistack';
import ChatButton from '@kiosk/components/ChatButton';
import AdminSidebar from './components/AdminConfig/AdminSidebar';

const App = () => {
  const [isLocalizationReady, setIsLocalizationReady] = useState(false);
  const [localizationError, setLocalizationError] = useState();
  const [faviconNotificationCount, setFaviconNotificationCount] = useState(0);

  // redux dispatch
  const dispatchRedux = useDispatch();

  // useAuth slice selectors
  const isUserAuthenticated = useSelector(selectIsAuthenticated);
  const authStatus = useSelector(selectAuthStatus);
  const user = useSelector(selectUser);

  // useAppConfig slice selectors
  const appConfig = useSelector(selectAppConfigIgnorePreviewMode);
  const tenantFound = useSelector(selectTenantFound);
  const appConfigStatus = useSelector(selectAppConfigStatus);

  // useUserPreferences slice selectors
  const userPreferencesStatus = useSelector(selectUserPreferencesStatus);
  const userPreferences = useSelector(selectUserPreferences);

  let appTheme = {};
  let faviconUrl = '';
  if (appConfig !== null) {
    const themeCustomizations = appConfig?.Theme?.Customizations || {};
    const themeMerged = _.merge(theme, themeCustomizations);
    appTheme = createTheme(themeMerged);
    appTheme = responsiveFontSizes(appTheme);

    // Set up favicon and title of webpage to dynamically load their custom one or the default. We add default option here to avoid a double load happening and the title and icon flashing for the user between sugar and theirs
    faviconUrl =
      appConfig?.Theme?.Favicon?.Url ||
      `${process.env.PUBLIC_URL}/images/sugar-favicon.png`;
  }

  if (appConfig !== null && localization.isReady()) {
    document.title =
      appConfig?.Theme?.Favicon?.Title ||
      localization.get('LBL_KIOSK_APP_NAME_DEFAULT');
  }

  // Load the app strings. Don't render until this is ready.
  useEffect(() => {
    if (
      isUserAuthenticated &&
      user !== null &&
      userPreferencesStatus === status.FULFILLED &&
      (!localization.isReady() || localization.needsRefresh())
    ) {
      const loadLocalization = async () => {
        let userLanguageCode;
        if (userPreferences?.App?.Language) {
          userLanguageCode = userPreferences?.App?.Language;
        } else if (user.language) {
          userLanguageCode = user.language;
        } else {
          userLanguageCode = 'en-US';
        }

        try {
          const localizationLoadingStatus = await localization.loadData(
            userLanguageCode
          );
          setIsLocalizationReady(localizationLoadingStatus);
        } catch (err) {
          setLocalizationError(err);
          throw new Error('Fetching the language strings failed', err.message, {
            cause: err,
          });
        }
      };

      loadLocalization();
    }
  }, [isUserAuthenticated, user, userPreferencesStatus, userPreferences]);

  // useEffect that contains the logic for our initial state setup on app load. Each if statement pertains to a different step in the process and will only happen once each
  useEffect(() => {
    if (appConfigStatus === status.IDLE) {
      dispatchRedux(getAppConfig());
    }

    if (tenantFound && authStatus === status.IDLE) {
      dispatchRedux(getAuthUser());
    }

    if (
      isUserAuthenticated &&
      user !== null &&
      userPreferencesStatus === status.IDLE
    ) {
      dispatchRedux(fetchUserPreferences(user.uid));
    }
  }, [
    isUserAuthenticated,
    user,
    userPreferencesStatus,
    dispatchRedux,
    appConfigStatus,
    appConfig,
    authStatus,
    tenantFound,
  ]);

  /**
   * Helper function that returns whether we are on an admin page path
   * @returns bool
   */
  const isAdminPage = () => {
    // TODO - Remove this once the new SSC layout is implemented.
    if (window.location.pathname.split('/').includes('features')) {
      return false;
    }
    return window.location.pathname.split('/').includes('admin');
  };

  // Special state where we don't have a tenant and need to show the Error page so we return early without all the extra components. Because the error page component utilizes the `useNavigate` hook, this must be wrapped in a BrowserRouter.
  if (appConfigStatus === status.FAILED && tenantFound === false) {
    return (
      <BrowserRouter>
        <HttpErrorPage tenantNotFoundError={true} />
      </BrowserRouter>
    );
  }

  if (tenantFound && localizationError) {
    return (
      <BrowserRouter>
        <HttpErrorPage localizationError={true} />
      </BrowserRouter>
    );
  }

  const isAuthenticated =
    authStatus === status.FULFILLED &&
    appConfigStatus === status.FULFILLED &&
    isUserAuthenticated;

  const isNotAuthenticated =
    authStatus === status.FULFILLED &&
    appConfigStatus === status.FULFILLED &&
    !isUserAuthenticated;

  if (isLocalizationReady && isAuthenticated) {
    return (
      // Ensuring all app context is loaded before we show the app
      <ThemeProvider theme={appTheme}>
        <Favicon
          url={faviconUrl}
          alertCount={faviconNotificationCount}
          alertFillColor={appTheme.palette.error.main}
          alertTextColor={appTheme.palette.common.white}
        />
        <Box
          component='main'
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            ml: user?.user_roles?.includes('admin') ? '3.75rem' : 0,
          }}>
          <SnackbarProvider maxSnack={1} autoHideDuration={6000}>
            <BrowserRouter>
              <Box
                id='top-bars'
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  ml: user?.user_roles?.includes('admin') ? '3.75rem' : 0,
                  zIndex: 10,
                }}>
                {isUserAuthenticated &&
                  tenantFound &&
                  // Do not show this if you are in the admin portion of the app
                  !isAdminPage() && <Navbar id='navbar' aria-label='navbar' />}
              </Box>
              {user?.user_roles?.includes('admin') && <AdminSidebar />}
              {
                <Container
                  sx={{
                    flex: '1 0 auto',
                    mt: isAdminPage() ? 0 : 8,
                    pt: isAdminPage() ? 0 : 3,
                  }}
                  id='main-content'
                  aria-label='main-content'
                  maxWidth={isAdminPage() ? 'xxl' : 'lg'}>
                  {!tenantFound ? (
                    <HttpErrorPage tenantNotFoundError={true} />
                  ) : (
                    <KioskBreadcrumbs>
                      <ErrorBoundary
                        FallbackComponent={HttpErrorFallback}
                        onError={onHttpErrorHandler}>
                        <>
                          {user && user.name === user.email && (
                            <FirstTimeLoginModal />
                          )}
                          <KioskRouter isTenantFound={tenantFound} />
                        </>
                      </ErrorBoundary>
                    </KioskBreadcrumbs>
                  )}
                </Container>
              }
            </BrowserRouter>
          </SnackbarProvider>
          {tenantFound && !isAdminPage() && (
            <Box>
              <Footer sx={{ flexShrink: 0 }} id='footer' aria-label='footer' />
              {appConfig?.SugarLive && (
                <ChatButton
                  faviconNotificationCount={faviconNotificationCount}
                  setFaviconNotificationCount={setFaviconNotificationCount}
                />
              )}
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  }

  // Even if the context is not loaded, we still have to render the auth pages in the case user is not logged in.
  if (!isLocalizationReady && isNotAuthenticated) {
    return (
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={HttpErrorFallback}
            onError={onHttpErrorHandler}>
            <KioskRouter isTenantFound={tenantFound} />
          </ErrorBoundary>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
};

export default App;
