import {
  USER_ROLE,
  ACCOUNT_MANAGER_ROLE,
  ADMIN_ROLE,
} from '@kiosk/config/Roles';

/**
 * Static list of menu items that are used for the KioskMenu component
 */
export const kioskMenuItems = [
  {
    label: 'LBL_KIOSK_MANAGE_USERS',
    id: 'manage-users',
    route: '/users',
    isAccessibleBy: [ACCOUNT_MANAGER_ROLE, ADMIN_ROLE],
    showDivider: true,
  },
  {
    label: 'LBL_KIOSK_CASES',
    id: 'cases',
    route: '/cases',
    isAccessibleBy: [USER_ROLE, ACCOUNT_MANAGER_ROLE, ADMIN_ROLE],
  },
  {
    label: 'LBL_KIOSK_DOCUMENTS',
    id: 'documents',
    route: '/documents',
    isAccessibleBy: [USER_ROLE, ACCOUNT_MANAGER_ROLE, ADMIN_ROLE],
  },
];

export const kioskAvatarMenuItems = [
  {
    label: 'LBL_KIOSK_MY_PROFILE',
    id: 'my-profile',
    route: '/profile',
    isAccessibleBy: [USER_ROLE, ACCOUNT_MANAGER_ROLE, ADMIN_ROLE],
    showDivider: true,
  },
  {
    label: 'LBL_KIOSK_SIGN_OUT',
    id: 'signout',
    route: '/logout',
    isAccessibleBy: [USER_ROLE, ACCOUNT_MANAGER_ROLE, ADMIN_ROLE],
  },
];
