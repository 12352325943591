/**
 * API functions related to user preferences service
 */

import {
  USER_PREFERENCES_API_V1_BASE,
  USER_PREFERENCES_API_V1_VERSION,
} from '@kiosk/config/ApiConfig';
import { kFetch } from './ApiHelper';

// Hardcoded state variable for testing purposes right now
// let state = {
//   RecordList: {
//     Cases: {
// order: 'name:asc',
// columnVisibilityModel: { id: false, status: false },
// 'desktop-list-view': 'data-grid',
// 'mobile-list-view': 'data-list',
// 'tablet-list-view': 'data-list',
//     },
//   },
// };

// let state = {
//   RecordList: {
//     Cases: {
//       order: 'status:asc',
//       columnVisibilityModel: {
//         case_number: true,
//         status: false,
//         type: false,
//         date_modified: false,
//         id: false,
//       },
//       'desktop-list-view': 'data-list',
//       'mobile-list-view': 'data-list',
//       'tablet-list-view': 'data-list',
//     },
//   },
// };

/**
 * Returns the authenticated user's preferences.
 */
export const getUserPreferences = async (userId) => {
  try {
    const res = await kFetch.get(
      `/${USER_PREFERENCES_API_V1_BASE}/${USER_PREFERENCES_API_V1_VERSION}/preferences/${userId}`
    );

    return res?.data?.data || null;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Updates and syncs the current logged in users preferences with the db
 * @param {Object} newPreferences
 */
export const syncUserPreferences = async (userId, newPreferences) => {
  const params = {
    user_preferences: newPreferences,
  };
  try {
    const res = await kFetch.put(
      `/${USER_PREFERENCES_API_V1_BASE}/${USER_PREFERENCES_API_V1_VERSION}/preferences/${userId}`,
      params
    );

    return res?.data?.data || null;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};
