/**
 * The roles are defined in Auth0 -> UserManagement -> Roles menu.
 */
export const USER_ROLE = 'user';
export const ACCOUNT_MANAGER_ROLE = 'account manager';
export const ADMIN_ROLE = 'admin';

export const USER_ROLE_DISPLAY_NAME = 'User';
export const ACCOUNT_MANAGER_ROLE_DISPLAY_NAME = 'Account Manager';
export const ADMIN_ROLE_DISPLAY_NAME = 'Admin';

export const ROLES_LIST = {
  'admin': [ADMIN_ROLE],
  'account manager': [ACCOUNT_MANAGER_ROLE],
  'user': [USER_ROLE],
};

export const ROLES_TO_DISPLAY_NAME_MAP = {
  [ROLES_LIST[USER_ROLE]]: USER_ROLE_DISPLAY_NAME,
  [ROLES_LIST[ACCOUNT_MANAGER_ROLE]]: ACCOUNT_MANAGER_ROLE_DISPLAY_NAME,
  [ROLES_LIST[ADMIN_ROLE]]: ADMIN_ROLE_DISPLAY_NAME,
};
