import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, Menu } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import CustomNotistackSnackbar from '@kiosk/components/CustomNotistackSnackbar';
import renderMenuItems from '@kiosk/components/KioskMenu/menuItems';
import localization from '@kiosk/i18n/localization';
import { selectUnsavedChanges } from '@kiosk/redux/slices/appConfig/appConfigSlice';
import { selectUser } from '@kiosk/redux/slices/auth/authSlice';

const KioskAvatarMenu = ({ kioskMenuItems }) => {
  // Move to hooks folder if logic is reused
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleItemClose = (event) => {
    if (unsavedChanges) {
      event.preventDefault();
      enqueueSnackbar(
        localization.get('LBL_KIOSK_UNSAVED_CHANGES'),
        {
          content: (key, message) => (
            <CustomNotistackSnackbar
              id={key}
              message={message}
              variant={'error'}
            />
          ),
        }
      );
    } else {
      setAnchorEl(null);
    }
  };
  const user = useSelector(selectUser);
  const unsavedChanges = useSelector(selectUnsavedChanges);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  return (
    <>
      <Button
        aria-haspopup='true'
        aria-expanded={open}
        id='kiosk-menu-avatar-button'
        data-testid='kiosk-menu-avatar-button'
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        sx={{
          flexGrow: 0,
          minWidth: 'auto',
          minHeight: '1.875rem',
          '& .MuiButton-endIcon': { ml: 0 },
          '&.Mui-focusVisible': {
            outline: `1px solid ${theme.palette.primary.main}`,
          },
        }}>
        <PersonIcon style={{ fontSize: '1.125rem' }} />
      </Button>
      <Menu
        id='kiosk-avatar-menu'
        data-testid='kiosk-avatar-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        role='menu'
        MenuListProps={{
          'aria-labelledby': 'kiosk-avatar-menu',
          role: 'menuitem',
        }}
        PaperProps={{
          elevation: 8,
          style: {
            width: 200,
          },
        }}>
        {renderMenuItems({ handleItemClose, user, kioskMenuItems })}
      </Menu>
    </>
  );
};

export default KioskAvatarMenu;
