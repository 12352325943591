import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import logo from '@kiosk/config/images/Sugar_logo_footer.svg';

/**
 * Main component function
 */
const Footer = () => {
  const SUGARCRM_PUBLIC_WEBSITE_URL = 'https://www.sugarcrm.com';

  return (
    <Box
      component='footer'
      sx={{
        width: '100%',
        zIndex: 0,
        py: 2,
        px: 2,
        display: 'flex',
        justifyContent: 'center',
      }}>
      <Typography
        variant='caption'
        component='div'
        sx={{ color: 'text.primary', textShadow: '1px 1px 2px #FFF' }}>
        <LocalizedString name='LBL_KIOSK_POWERED_BY_SUGARCRM' />
      </Typography>
      <Link
        href={SUGARCRM_PUBLIC_WEBSITE_URL}
        target='_blank'
        underline='none'
        sx={{
          height: '13.74px',
          width: '73.96px',
          pl: '3px',
        }}>
        <Box
          sx={{ filter: 'drop-shadow(1px 1px 2px #FFF)' }}
          component='img'
          alt={SUGARCRM_PUBLIC_WEBSITE_URL}
          src={logo}
        />
      </Link>
    </Box>
  );
};

export default Footer;
