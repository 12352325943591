import { isArray } from '@craco/craco/lib/utils';
import { useEffect, useRef } from 'react';

/**
 * This hook gets called only when the dependencies change but not during initial render.
 *
 * @param {function} effect The `useEffect` callback function.
 * @param {array} deps An array of dependencies.
 *
 * @example
 * ```
 *  useNonInitialEffect(()=>{
 *      alert("Dependency changed!");
 * },[dependency]);
 * ```
 */
export const useNonInitialEffect = (effect, deps) => {
  if (!isArray(deps)) {
    throw new Error('Must pass dependencies as an array');
  }
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns = () => {};

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
    // This eslint disable line has to be here because react thinks we are using useEffect in a standard way, and we are not
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
