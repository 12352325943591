import { createSlice } from '@reduxjs/toolkit';
import initialState from '@kiosk/redux/slices/listsContext/initialState';

/**
 * Formats the search and filters returned from various components
 * @param {object} values
 * @returns the formatted values object
 */
const formatValues = (values) => {
  if (values === null || Object.values(values).every((el) => el === null)) {
    return null;
  }

  let newVals = [];
  for (let key in values) {
    newVals.push({ [key]: values[key] });
  }

  return newVals;
};

/**
 * Formats the filters returned from various components
 * @param {object} values
 * @returns the formatted values object
 */
const formatUsersFilterValues = (values) => {
  if (values === null || Object.values(values).every((el) => el === null)) {
    return null;
  }

  return values.values.toString();
};

/**
 * Formats the search returned from various components
 * @param {object} values
 * @returns the formatted values object
 */
const formatUsersSearch = (values) => {
  if (values === null || Object.values(values).every((el) => el === null)) {
    return null;
  }

  let newVals = '';
  for (let key in values) {
    if (key.includes('user_metadata')) newVals += `${key}:"${values[key]}" OR `;
    else if (values[key].length < 3) newVals += `${key}:${values[key]}* OR `;
    else newVals += `${key}:*${values[key]}* OR `;
  }

  return newVals;
};

const getListComboFilter = (listState) => {
  if (!listState.filter && !listState.search) {
    return null;
  } else if (!listState.filter) {
    return listState.search;
  } else if (!listState.search) {
    return listState.filter;
  } else {
    return [{ $and: [...listState.filter, ...listState.search] }];
  }
};

const listsContextSlice = createSlice({
  name: 'listsContext',
  initialState,
  reducers: {
    updateInitialListState(state, action) {
      const listName = action.payload.name;

      if (action.payload.order) {
        state.lists[listName].order = action.payload.order;
      }

      if (action.payload.columnVisibilityModel) {
        state.lists[listName].columnVisibilityModel =
          action.payload.columnVisibilityModel;
      }
    },
    updateFilterValues(state, action) {
      const listName = action.payload.name;

      // unsupported list
      if (!state.lists[listName]) {
        return state;
      }

      if (listName.includes('Users')) {
        state.lists[listName].filter = formatUsersFilterValues(
          action.payload.value
        );
      } else {
        state.lists[listName].filter = formatValues(action.payload.value);

        state.lists[listName].comboFilter = getListComboFilter(
          state.lists[listName]
        );
      }

      state.lists[listName].page = 0;
    },
    updateSearch(state, action) {
      const listName = action.payload.name;

      // unsupported list
      if (!state.lists[listName]) {
        return state;
      }

      if (listName.includes('Users')) {
        const formattedQuery = formatUsersSearch(action.payload.value);

        const searchValue = formattedQuery
          ? '(' + formattedQuery.slice(0, -4) + ')'
          : null;

        state.lists[listName].search = searchValue;
      } else {
        const formattedQuery = formatValues(action.payload.value);

        const searchValue = formattedQuery ? [{ $or: formattedQuery }] : null;

        state.lists[listName].search = searchValue;
        state.lists[listName].comboFilter = getListComboFilter(
          state.lists[listName]
        );
      }

      state.lists[listName].page = 0;
    },
    changePage(state, action) {
      const listName = action.payload.name;
      state.lists[listName].page = action.payload.page;
    },
    changeSortOrder(state, action) {
      const listName = action.payload.name;
      state.lists[listName].page = 0;
      state.lists[listName].order = action.payload.order;
    },
    changeColumnVisibilityModel(state, action) {
      const listName = action.payload.name;
      state.lists[listName].columnVisibilityModel =
        action.payload.columnVisibilityModel;
    },
    changeView(state, action) {
      const listName = action.payload;
      state.lists[listName].page = 0;
    },
  },
});

export const {
  updateInitialListState,
  updateFilterValues,
  updateSearch,
  changePage,
  changeSortOrder,
  changeColumnVisibilityModel,
  changeView,
} = listsContextSlice.actions;

export const selectLists = (state) => state.listsContext.lists;

export default listsContextSlice.reducer;
