import React, { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import { useTheme } from '@emotion/react';
import { Alert, useMediaQuery } from '@mui/material';
import { LocalizedString } from '../utils/LocalizedString';

const CustomNotistackSnackbar = forwardRef(
  ({ id, variant, message, module }, ref) => {
    const theme = useTheme();
    const isPhoneSize = useMediaQuery(theme.breakpoints.down('sm'));
    const isTabletSize = useMediaQuery(theme.breakpoints.down('md'));
    const snackMaxWidth = isPhoneSize
      ? { maxWidth: '100vw' }
      : isTabletSize
      ? { maxWidth: '60vw' }
      : { maxWidth: '40vw' };
    const { closeSnackbar } = useSnackbar();

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent sx={{ maxWidth: snackMaxWidth }} ref={ref}>
        <Alert onClose={onClose} severity={variant} sx={{ width: '100%' }}>
          <LocalizedString name={message} module={module} />
        </Alert>
      </SnackbarContent>
    );
  }
);

export default CustomNotistackSnackbar;
