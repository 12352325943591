/**
 * API functions related to user management service
 */

import {
  USER_MANAGEMENT_API_V1_BASE,
  USER_MANAGEMENT_API_V1_VERSION,
} from '@kiosk/config/ApiConfig';
import { kFetch } from './ApiHelper';

/**
 * Returns the user details for the currently logged in user
 *
 * Sample:
 * {
      user_id: 'auth0|622faa6863767e00704e6c1b',
      email: 'test@sugarcrm.com',
      name: 'Fake User',
      user_metadata: {
        phone_work: '12222222222',
        phone_mobile: '13333333333',
        organization: 'SugarCRM',
        language: 'en_us',
        timezone: 'est',
      }
    };
 * @param {string} userId the auth0 user id
 * @returns the user detils object flattened to be usable by react-hook-form
 */
export const getUserDetails = async (userId) => {
  try {
    const res = await kFetch.get(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/details/${userId}`
    );

    if (res?.data?.data) {
      return flattenUserDetails(res.data.data);
    }
    return null;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Updates the user details for the currently logged in user and syncs these updates with the CRM
 *
 * * Sample:
 * {
      user_id: 'auth0|622faa6863767e00704e6c1b',
      email: 'test@sugarcrm.com',
      name: 'Fake User',
      given_name: 'Fake',
      family_name: 'User',
      phone_work: '12222222222',
      phone_mobile: '13333333333',
      organization: 'SugarCRM',
      language: 'en_us',
      timezone: 'est',
      }
    };
 * @param {string} userId the auth0 user id
 * @param {Object} data data formatted from react-hook-form
 */
export const updateUserDetails = async (userId, data) => {
  data.name = `${data.given_name} ${data.family_name}`;

  try {
    const ret = await kFetch.put(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/details/${userId}`,
      {
        data,
      }
    );

    return ret?.data?.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Fetches the list of users by status
 *
 * @param {string} status - The user status. Supported statuses are: "active", "blocked", "invited".
 * @param {object} params - Query parameters. Supported params:
 * "page" - Page index of the results to return. First page is 0.
 * "perPage" - Number of results per page. If this parameter is missing than it will default to 50.
 * @returns response array
 *
 * * Sample:
 * {
    length: 2, // current length
    limit: 2,  // if no "perPage" param is provided, 50 is default
    next: "",  // checkpoint pagination - not used
    start: 0,  // offset pagination - this is page * perPage
    total: 12, // total length
    users: [
      {
        email: 'gabriel.timofti@sugarcrm.com',
        family_name: 'Timofti',
        given_name: 'Gabriel',
        last_login: '2023-07-19T18:05:30.383Z',
        name: 'Gabriel Timofti',
        roles: [
          'user',
          'admin',
          'account manager'
        ],
        user_id: 'auth0|64666204c2b8380d9bdc189a',
        user_metadata: {
          account_id: 'Gabi LLC'
        }
      },
      {
        email: 'gabriel.timofti+admin@sugarcrm.com',
        family_name: 'Admin',
        given_name: 'Gabriel',
        last_login: '2023-07-19T17:54:09.176Z',
        name: 'Gabriel Admin',
        roles: [
          'account manager',
          'user'
        ],
        user_id: 'auth0|64823629b73c0ae1b1b6dfc5',
        user_metadata: {
          account_id: '012345'
        }
      }
    ]
  }
 */
export const listUsersByStatus = async (status, params) => {
  try {
    const res = await kFetch.get(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/list/${status}`,
      params
    );

    return res?.data?.data || null;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Sends invitations for the given invites array
 * @param {Object} invites
 * @returns the list of successfully invited users
 *
 * * Sample:
 {
  "invites": [
      {
          "email": "joesmith@tyson.com",
          "account": "123abc",
          "roles": ["user"]
      },
      {
          "email": "samsmith@tyson.com",
          "account": "123abc",
          "roles": ["user", "account manager"]
      },
  ]
}
 */
export const inviteUsers = async (invites) => {
  try {
    const res = await kFetch.post(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/invitations`,
      {
        invites,
      }
    );

    return res.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Sends the reset password email to the given user id
 * @param {string} uid
 * @returns
 */
export const resetPassword = async (uid) => {
  try {
    const res = await kFetch.post(`/rest/resetpassword/${uid}`, {});

    return res.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Deactivates a user in the system (blocked field is true in auth0)
 * @param {string} uid
 * @returns
 */
export const deactivateUser = async (uid) => {
  try {
    const res = await kFetch.put(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/deactivate/${uid}`,
      {}
    );

    return res.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Reactivates a user in the system (blocked field is true in auth0)
 * @param {string} uid
 * @returns
 */
export const reactivateUser = async (uid) => {
  try {
    const res = await kFetch.put(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/reactivate/${uid}`,
      {}
    );

    return res.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * Revokes an invitation
 * @param {string} uid
 * @returns
 */
export const revokeInvitation = async (uid) => {
  try {
    const res = await kFetch.delete(
      `/${USER_MANAGEMENT_API_V1_BASE}/${USER_MANAGEMENT_API_V1_VERSION}/invitations/${uid}`
    );

    return res.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};

/**
 * This is a helper function to flatten the user_metadata object into top level key value pairs in the user details object. This allows it to be used by the user profile page more easily
 * @param {Object} ob
 * @returns The userDetails object flattened to not have the nested user_metadata
 */
const flattenUserDetails = (ob) => {
  let result = {};

  for (const i in ob) {
    if (i === 'user_metadata') {
      const temp = flattenUserDetails(ob[i]);
      for (const j in temp) {
        result[j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }

  return result;
};
