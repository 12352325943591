// Language dropdown static key value mappings
export const languages = {
  'en-US': 'English (US)',
  'de-DE': 'Deutsch',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'pt-BR': 'Português Brasileiro',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
};
