import { Link } from 'react-router-dom';
import { MenuItem, Typography } from '@mui/material';
import { ColorGray400 } from '@maple-syrup/build/javascript/sugarColorPalette';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import { isAllowedByRole } from '@kiosk/utils/roles';

const renderMenuItems = ({ handleItemClose, user, kioskMenuItems }) => {
  return kioskMenuItems
    .filter((item) => isAllowedByRole(user?.user_roles, item.isAccessibleBy))
    .map((item) => (
      <MenuItem
        key={item.id}
        data-testid={`${item.id}-menu-item`}
        onClick={handleItemClose}
        sx={{
          py: 1.5,
          borderBottom: item.showDivider ? `1px solid ${ColorGray400}` : '0',
        }}
        component={Link}
        to={item.route}>
        <Typography sx={{ color: 'primary.main' }} variant='button'>
          <LocalizedString name={item.label} />
        </Typography>
      </MenuItem>
    ));
};

export default renderMenuItems;
