import { dataTableConfig } from '@kiosk/config/AdminConfig';

// The initialState object for the recordLists slice
const initialState = {
  lists: {
    Cases: {
      filter: null,
      search: null,
      comboFilter: null,
      page: 0,
      pageSize: dataTableConfig.pageSizeDefault,
      order: null,
      columnVisibilityModel: { id: false },
    },
    Documents: {
      filter: null,
      search: null,
      comboFilter: null,
      page: 0,
      pageSize: dataTableConfig.pageSizeDefault,
      order: null,
      columnVisibilityModel: { id: false },
    },
    Users_active: {
      filter: null,
      search: null,
      page: 0,
      pageSize: dataTableConfig.pageSizeDefault,
      order: null,
      columnVisibilityModel: { id: false },
    },
    Users_blocked: {
      filter: null,
      search: null,
      page: 0,
      pageSize: dataTableConfig.pageSizeDefault,
      order: null,
      columnVisibilityModel: { id: false },
    },
    Users_invited: {
      filter: null,
      search: null,
      page: 0,
      pageSize: dataTableConfig.pageSizeDefault,
      order: null,
      columnVisibilityModel: { id: false },
    },
  },
};

export default initialState;
