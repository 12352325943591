import React from 'react';
import { createRoot } from 'react-dom/client';
import App from '@kiosk/App';
import '@kiosk/styles/index.css';
import { CssBaseline } from '@mui/material';
import { setupStore } from '@kiosk/redux/store/store';
import { Provider } from 'react-redux';
import MuiXLicense from './muiXLicense';
import * as Sentry from '@sentry/react';

const env = window.REACT_APP_ENV ?? 'local';
let dsn;

if (env === 'prod') {
  dsn =
    'https://99e3881e4e3760023e25e88678f94f8f@o76959.ingest.us.sentry.io/4507295073894400';
}
if (env === 'qa' || env === 'stg') {
  dsn =
    'https://750961dcfbbae20955e0c1db70917ad7@o76959.ingest.us.sentry.io/4507295917670400';
}

if (dsn) {
  Sentry.init({
    environment: env,
    dsn: dsn,
  });
}

const store = setupStore();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <App />
      <MuiXLicense />
    </Provider>
  </React.StrictMode>
);
