import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@kiosk/redux/slices/auth/authSlice';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Box,
  Link,
} from '@mui/material';
import { Block, ErrorOutline, FindInPage, Lock } from '@mui/icons-material';
import { Link as RLink, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import { ColorGray500 } from '@maple-syrup/build/javascript/sugarColorPalette';

const StyledLink = styled(Box)(({ theme }) => ({
  textDecoration: 'none',
  '& a': { textDecoration: 'none', color: theme.palette.primary.main },
}));

const getErrorDetailsFromCode = (httpCode) => {
  let errorDetails = {};
  switch (httpCode) {
    case 403:
      errorDetails = {
        errorTitle: 'LBL_KIOSK_403_TITLE',
        errorString: 'LBL_KIOSK_403_MESSAGE',
        errorIcon: Lock,
      };
      break;
    case 404:
      errorDetails = {
        errorTitle: 'LBL_KIOSK_404_TITLE',
        errorString: 'LBL_KIOSK_404_MESSAGE',
        errorIcon: FindInPage,
      };
      break;
    case 500:
      errorDetails = {
        errorTitle: 'LBL_KIOSK_500_TITLE',
        errorString: 'LBL_KIOSK_500_MESSAGE',
        goBack: true,
        errorIcon: ErrorOutline,
      };
      break;
    default:
      errorDetails = {
        errorTitle: 'LBL_KIOSK_500_TITLE',
        errorString: 'LBL_KIOSK_500_MESSAGE',
        goBack: true,
        errorIcon: ErrorOutline,
      };
  }
  return errorDetails;
};

const getErrorDetailsFromURL = (error, errorDescription) => {
  let errorDetails = {};
  switch (errorDescription) {
    case 'user is blocked':
      errorDetails = {
        errorTitle: 'Unauthorized',
        errorString:
          'Your user has been deactivated. Please contact your administrator.',
        errorIcon: Block,
      };
      break;
    default:
      errorDetails = {
        errorTitle: 'LBL_KIOSK_404_TITLE',
        errorString: 'LBL_KIOSK_404_MESSAGE',
        errorIcon: FindInPage,
      };
  }

  return errorDetails;
};

/**
 * HttpErrorPage component defines the page component for all http error handling in the application
 * @param {number} code the error code. Defaults to 500
 * @param {string} title a custom title to be used for the error if desired, else it will default to the message defined for the code
 * @param {string} description error description from the server
 * @param {boolean} tenantNotFoundError set to true in order to trigger the unique tenantNotFoundError. Defaults to false
 * @param {Function} resetErrorBoundary passed when this component is created via the react-error-boundary listener. Otherwise leave null
 */
const HttpErrorPage = ({
  code,
  title = '',
  description = '',
  tenantNotFoundError = false,
  localizationError = false,
  resetErrorBoundary = null,
}) => {
  const navigate = useNavigate();
  const isUserAuthenticated = useSelector(selectIsAuthenticated);

  let errorTitle = '';
  let errorString = '';
  let ErrorIcon = null;
  let goBack = false;
  if (tenantNotFoundError) {
    errorTitle = 'Tenant not found';
    errorString =
      'Please make sure that you have the correct URL. For further assistance, please contact Support.';
    ErrorIcon = FindInPage;
  } else if (localizationError) {
    errorTitle = 'Server not responding';
    errorString = 'Something went wrong on our end.';
    ErrorIcon = ErrorOutline;
  } else {
    // If HTTP error code is present, get error details from it. Otherwise, look for additional parameters.
    let errorDetails = code
      ? getErrorDetailsFromCode(code)
      : getErrorDetailsFromURL(title, description);
    errorTitle = errorDetails?.errorTitle;
    errorString = errorDetails?.errorString;
    ErrorIcon = errorDetails?.errorIcon;
    goBack = errorDetails?.goBack || false;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Card
        data-testid={'HttpErrorPage'}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: '4rem',
          maxWidth: '35rem',
        }}>
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Typography sx={{ fontSize: 24, mb: '2rem' }} color='text.primary'>
            <LocalizedString name={errorTitle || title} />
          </Typography>
          <ErrorIcon
            sx={{
              fontSize: '4.5rem',
              color:
                tenantNotFoundError || localizationError
                  ? ColorGray500
                  : 'info.main',
              mb: '2rem',
            }}
          />
          <Typography
            sx={{
              fontSize: '.75rem',
              color: 'text.secondary',
            }}>
            <LocalizedString name={errorString} />
          </Typography>
        </CardContent>
        {!tenantNotFoundError && !localizationError && (
          <CardActions>
            <StyledLink onClick={resetErrorBoundary}>
              {goBack ? (
                <RLink
                  to={''}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}>
                  <LocalizedString name='LBL_KIOSK_GO_BACK' />
                </RLink>
              ) : (
                <Link href='/'>
                  <LocalizedString
                    name={
                      isUserAuthenticated
                        ? 'LBL_KIOSK_BACK_TO_HOME'
                        : 'Back to login'
                    }
                  />
                </Link>
              )}
            </StyledLink>
          </CardActions>
        )}
      </Card>
    </Box>
  );
};

export default HttpErrorPage;
