import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '@kiosk/redux/slices/auth/authSlice';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUserDetails } from '@kiosk/api/UserManagementApi';
import FirstTimeLoginModalForm from '@kiosk/components/FirstTimeLoginModal/FirstTimeLoginModalForm';
import { LocalizedString } from '@kiosk/components/utils/LocalizedString';
import { useHttpErrorBoundaryPageEffect } from '@kiosk/hooks/useHttpErrorBoundaryPageEffect';
import {
  firstTimeLoginModalBoxDefs,
  firstTimeLoginModalMetadata,
} from '@kiosk/metadata/FirstTimeLoginModal/firstTimeLoginModalMetadata';

/**
 * This component handles capturing the first and last name
 * of the Kiosk user the first time they log into the Kiosk
 */
const FirstTimeLoginModal = () => {
  const user = useSelector(selectUser);

  const [open, setOpen] = useState(true);
  const [isNameSaved, setIsNameSaved] = useState(null);
  const [currentUser, updateCurrentUser] = useState(null);
  const [isUserDetailsLoaded, setIsUserDetailsLoaded] = useState(false);
  const [httpError, setHttpError] = useState(null);

  const theme = useTheme();
  const isMobileScreenSize = useMediaQuery(theme.breakpoints.down('sm'));
  // Set up page to use our custom error boundary
  useHttpErrorBoundaryPageEffect(httpError);

  /**
   * In case that just after onboarding is complete the user does a force page reload,
   * the session would not be updated and the modal will still appear.
   * To prevent this, check if first name, and last were saved successfully,
   * and if this is true, than do not display the modal anymore.
   */
  useEffect(() => {
    (async () => {
      try {
        const userDetails = await getUserDetails(user.uid);
        updateCurrentUser(userDetails);
        if (
          userDetails.given_name !== undefined &&
          userDetails.given_name !== '' &&
          userDetails.family_name !== undefined &&
          userDetails.family_name !== ''
        ) {
          setIsNameSaved(true);
        } else {
          setIsNameSaved(false);
        }
        setIsUserDetailsLoaded(true);
      } catch (err) {
        setHttpError(err);
      }
    })();
  }, [user.uid]);

  return (
    !isNameSaved &&
    isUserDetailsLoaded && (
      <Modal
        open={open}
        id='first-time-login-modal'
        data-testid='first-time-login-modal'>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobileScreenSize ? '17em' : '28em',
            minHeight: isMobileScreenSize ? '17em' : '15em',
            bgcolor: 'background.paper',
            borderRadius: '0.3em',
            p: 3,
          }}>
          <Typography
            id='first-time-login-modal-title'
            variant='h6'
            component='h2'>
            <LocalizedString name={firstTimeLoginModalBoxDefs.title_label} />
          </Typography>
          <Typography id='first-time-login-modal-description' sx={{ mt: 2 }}>
            <LocalizedString
              name={firstTimeLoginModalBoxDefs.description_label}
            />
          </Typography>
          <FirstTimeLoginModalForm
            currentUser={currentUser}
            firstTimeLoginModalFields={firstTimeLoginModalMetadata}
            setOpen={setOpen}
          />
        </Box>
      </Modal>
    )
  );
};

export default FirstTimeLoginModal;
