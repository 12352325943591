import { createSlice } from '@reduxjs/toolkit';
import { breadcrumbNameMap } from '@kiosk/metadata/base/baseMetadata';

const initialState = {
  breadcrumbNameMap: breadcrumbNameMap,
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    addBreadcrumb(state, action) {
      let { path, name, containerWidth } = action.payload;
      state.breadcrumbNameMap[path] = {
        label: name,
        containerWidth: containerWidth ?? '',
      };
    },
  },
});

export const selectBreadcrumbNameMap = (state) =>
  state.breadcrumbs.breadcrumbNameMap;

export const { addBreadcrumb } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
