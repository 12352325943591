export const supportedModules = {
  home: 'Home',
  adminConfig: 'AdminConfig',
  userManagement: 'UserManagement',
  cases: 'Cases',
  documents: 'Documents',
  contacts: 'Contacts',
};

export const breadcrumbNameMap = {
  '/cases': {
    label: 'LBL_KIOSK_CASES',
    module: 'Cases',
    isBase: true,
  },
  '/users': {
    label: 'LBL_KIOSK_MANAGE_USERS',
    module: 'UserManagement',
    isBase: true,
  },
};
