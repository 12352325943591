import axios from 'axios';
import qs from 'qs';

/**
 * paramSerializer function to be used for any axios call with the params argument.
 * Allows for URI encoding to be done correctly for communication with Sugar
 * @param {object} params
 * @returns the serialized version of the params for axios
 */
export const paramsSerializer = (params) => {
  return qs.stringify(params);
};

/**
 * Small helper to reduce redundancy and make request objects consistent
 */
class KioskFetch {
  #requestHost = window.location.host;

  async get(resource, params) {
    return await axios.get(resource, {
      params,
      paramsSerializer,
    });
  }

  async post(resource, body, params = {}) {
    return await axios.post(resource, body, {
      params,
      paramsSerializer,
    });
  }

  async put(resource, body, params = {}) {
    return await axios.put(resource, body, {
      params,
      paramsSerializer,
    });
  }

  async delete(resource, params) {
    return await axios.delete(resource, {
      params,
      paramsSerializer,
    });
  }
}

export const kFetch = new KioskFetch();
