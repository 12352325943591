import { useEffect } from 'react';

/**
 * useHttpErrorBoundaryPageEffect Hook for use in any page component that needs to utilize our custom error boundary
 * component. This component will throw a JavaScript error outside an async HTTP call environment to ensure our error
 * boundary will pick it up and render the appropriate HTTP error page.
 */
export const useHttpErrorBoundaryPageEffect = (error) => {
  useEffect(() => {
    if (!error) return;
    throw new Error(error.message, { cause: error.cause });
  }, [error]);
};
