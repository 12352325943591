export const sidebarDefs = [
  {
    name: 'theme',
    label: 'LBL_KIOSK_SIDEBAR_THEME',
    iconName: 'pages-lg',
    path: '/admin/config/theme',
  },
  {
    name: 'features',
    label: 'LBL_KIOSK_SIDEBAR_FEATURES',
    iconName: 'features-lg',
    path: '/admin/config/features',
  },
];
