import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTenant } from '@kiosk/api/TenantApi';
import { status } from '@kiosk/redux/constants';
import skipStandardAuthFlow from '@kiosk/utils/skipStandardAuthFlow';
import AppConfig from '@kiosk/metadata/AdminConfig/base/appConfig';
import _ from 'lodash';

const initialState = {
  appConfig: null,
  tenantFound: null,
  status: status.IDLE,
  error: null,
  previewAppConfig: null,
  isPreviewMode: false,
  unsavedChanges: false,
};

export const getAppConfig = createAsyncThunk(
  'appConfig/getAppConfig',
  async () => {
    if (skipStandardAuthFlow()) {
      return {
        AppConfiguration: null,
      };
    } else {
      return await getTenant();
    }
  }
);

/**
 * mergeAndCreateAppConfigState merges our imported AppConfig default structure and the current state of appConfig. This is a similar process to what MUI does behind the scenes when making our custom theme file
 * @param {Object} appConfigState
 * @returns the merged appConfig state
 */
function mergeAndCreateAppConfigState(appConfigState) {
  return _.merge(AppConfig, appConfigState);
}

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    toggleIsPreviewMode(state, action) {
      const { isPreviewMode } = action.payload;
      state.isPreviewMode = isPreviewMode;
    },
    toggleUnsavedChanges(state, action) {
      const { unsavedChanges } = action.payload;
      state.unsavedChanges = unsavedChanges;
    },
    updatePreviewAppConfig(state, action) {
      const { appConfig } = action.payload;
      state.previewAppConfig = appConfig;
    },
    removePreviewAppConfig(state, action) {
      state.previewAppConfig = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAppConfig.pending, (state, action) => {
        state.status = status.PENDING;
      })
      .addCase(getAppConfig.fulfilled, (state, action) => {
        const appConfig = mergeAndCreateAppConfigState(
          action.payload.AppConfiguration
        );
        state.tenantFound = true;
        state.appConfig = appConfig;
        state.previewAppConfig = state.appConfig;
        state.status = status.FULFILLED;
      })
      .addCase(getAppConfig.rejected, (state, action) => {
        state.tenantFound = false;
        state.error = action.error.message;
        state.status = status.FAILED;
      });
  },
});

export const selectAppConfig = (state) =>
  state.appConfig.isPreviewMode
    ? state.appConfig.previewAppConfig
    : state.appConfig.appConfig;
export const selectTenantFound = (state) => state.appConfig.tenantFound;
export const selectAppConfigStatus = (state) => state.appConfig.status;
export const selectPreviewAppConfig = (state) =>
  state.appConfig.previewAppConfig;
export const selectIsPreviewMode = (state) => state.appConfig.isPreviewMode;
export const selectUnsavedChanges = (state) => state.appConfig.unsavedChanges;

// This selector looks strange but is used for a very specific purpose. Normally we want the application to "just work" in preview mode, so the `selectAppConfig` will select the appropriate one based on the mode. This ensures all of our components work in both modes without any logic on their end. But we need a way to select the original App Config object even when the app is in preview mode.
export const selectAppConfigIgnorePreviewMode = (state) =>
  state.appConfig.appConfig;

export const {
  updatePreviewAppConfig,
  removePreviewAppConfig,
  toggleIsPreviewMode,
  toggleUnsavedChanges,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
