import { kFetch } from './ApiHelper';

/**
 * Grabs the currently authenticated user for use in frontend authentication layer. It also triggers the registration of any new users to the system in the Dynamodb and in Mango External Users table
 * @returns The data associated with the logged in user needed for authentication and identification
 */
export const getCurrentAuthenticatedUser = async () => {
  try {
    const res = await kFetch.get('/rest/auth/user', {
      withCredentials: true,
    });

    return res.data;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};
