import getQueryParam from "@kiosk/utils/getQueryParam";

/**
 * Grabs the organization, organization_name, and invitation params from the query params and returns them attached to the login route.
 * @param {string} baseRoutePath
 * @returns string
 */
const getInvitationOrPwReset = (baseRoutePath) => {
  let organization = getQueryParam('organization');
  let organizationName = getQueryParam('organization_name');
  let invitation = getQueryParam('invitation');
  let issuer = getQueryParam('iss');
  if (
    organization !== '' &&
    organizationName !== '' &&
    (invitation !== '' || issuer !== '')
  ) {
    let queryParams = {
      organization,
      organization_name: organizationName,
      ...(invitation !== '' && { invitation }),
      ...(issuer !== '' && { iss: issuer }),
    };
    let formattedQueryParams = new URLSearchParams(queryParams);
    return encodeURI(`${baseRoutePath}?${formattedQueryParams.toString()}`);
  }

  return encodeURI(`${baseRoutePath}`);
};

export default getInvitationOrPwReset;
