import { intervalToDuration, formatDuration } from 'date-fns';
import _ from 'lodash';

import { getOverrideLanguageStrings } from '@kiosk/api/LanguageApi';
import enUS from '@kiosk/i18n/lang/en-US.json';
class Localization {
  #isReady = false;
  #needsRefresh = false;
  #languageStrings = null; // The language data to be used for all label interactions
  #overrideStrings = null; // The current override object
  #fallbackStrings = null; // The english language strings to be used as fallback when translation string isn't found in languageData

  /**
   * Load the language strings from our backend.
   * @param {string} langCode language code in iso format
   * @returns {Promise} true if language was loaded successfully
   */
  async loadData(langCode) {
    try {
      const { overrideStrings } = await getOverrideLanguageStrings(langCode);
      const baseLangStrings = await import(`@kiosk/i18n/lang/${langCode}.json`);
      this.#languageStrings = _.merge(baseLangStrings, overrideStrings);
      this.#overrideStrings = overrideStrings;
    } catch (err) {
      console.error('unable to get localized strings, defaulting to english');
      this.#languageStrings = enUS;
    }

    this.#fallbackStrings = enUS;
    this.#isReady = true;
    return true;
  }

  /**
   * Returns true if localization data has been fetched, and false otherwise.
   * @returns {boolean}
   */
  isReady() {
    return this.#isReady;
  }

  /**
   * Returns true if the localization service needs to refresh and refetch
   * @returns {boolean}
   */
  needsRefresh() {
    return this.#needsRefresh;
  }

  /**
   * Sets the localization service so that it will force a refetch of the localization data. This function is invoked when we make updates to user language preference.
   */
  setLocalizationNeedsRefresh() {
    this.#needsRefresh = true;
  }

  /**
   * Gets the internal localization data
   * @returns {Object} raw language data
   */
  getLanguageStrings() {
    return this.#languageStrings;
  }

  getOverrideStrings() {
    return this.#languageStrings;
  }

  getFallbackStrings() {
    return this.#fallbackStrings;
  }

  /**
   * Gets a string by provided name.
   * @param {string} key language key to retrieve
   * @param {string} module (optional) name of the module the language key is in. If not set, defaults to
   *                        global scope. Also falls back to global scope if not found in specified module.
   * @returns {string}
   */
  get(key, module) {
    if (!this.isReady()) {
      return key;
    }

    let languageData = this.getLanguageStrings();
    let fallbackData = this.getFallbackStrings();
    let value = languageData['app_strings'][key];
    if (module && languageData['mod_strings']?.[module]) {
      value = languageData['mod_strings'][module][key] || value;
    }

    // fallback to englishStrings if one is not found in current current languageData object
    if (!value && fallbackData) {
      value = fallbackData['app_strings'][key];
      if (module && fallbackData['mod_strings']?.[module]) {
        value = fallbackData['mod_strings'][module][key] || value;
      }
    }

    return value || key;
  }

  /**
   * Applies `values` to the provided template string.
   *
   * Example:
   *   applyTemplate(
   *     'Hello {userName}, welcome to {appName}',
   *     {
   *       userName: 'New User',
   *       appName: 'Kiosk',
   *     }
   *   )
   * Returns:
   *   'Hello New User, welcome to Kiosk'
   *
   * @param {string} template
   * @param {Object} values
   * @returns string
   */
  applyTemplate(template, values) {
    if (!values) {
      return template;
    }
    return template.replace(/{(\w+)}/g, (match, key) => values?.[key] || match);
  }

  /**
   * Gets a label by key and module, and then uses it as a template with the provided values applied.
   * This is the same as `localization.applyTemplate(localization.get(key, module), values)`
   * @param {string} key
   * @param {string} module
   * @param {Object} values
   * @returns string
   */
  getAndApplyTemplate(key, module, values) {
    if (!this.isReady()) {
      return key;
    }

    const label = this.get(key, module);
    return label === key ? label : this.applyTemplate(label, values);
  }

  /**
   * Gets a an ordered dropdown by provided name, in an array format.
   * @param {string} name name of the dropdown to retrieve
   * @returns {Array} array containing the dropdown. Data is returned in the following format:
   * [
   *   {
   *     key: 'internal_key1',
   *     value: 'Displayed Value 1'
   *   },
   *   {
   *     key: 'internal_key2',
   *     value: 'Displayed Value 2',
   *   },
   *   ...
   * ]
   */
  getAppList(name) {
    if (!this.isReady()) {
      return [];
    }

    let languageData = this.getLanguageStrings();
    let fallbackData = this.getFallbackStrings();
    let appLists = languageData['app_list_strings'];
    let listValue = appLists?.[name];
    if (!listValue && fallbackData) {
      // fallback to englishStrings if one is not found in current current languageData object
      listValue = fallbackData['app_list_strings'][name];
    }

    return listValue
      ? Object.entries(listValue).map(([key, value]) => ({
          key,
          value,
        }))
      : [];
  }

  /**
   * Gets an unordered dropdown by provided name, in an object format.
   * @param {string} name name of the dropdown to retrieve
   * @returns {Object} object containing the dropdown values. As this is an object, it is unordered.
   */
  getAppListObject(name) {
    if (!this.isReady()) {
      return {};
    }
    let languageData = this.getLanguageStrings();
    let fallbackData = this.getFallbackStrings();
    let appLists = languageData['app_list_strings'];
    let listValue = appLists?.[name];
    if (!listValue && fallbackData) {
      // fallback to englishStrings if one is not found in current current languageData object
      listValue = fallbackData['app_list_strings'][name];
    }
    return listValue || {};
  }

  /**
   * Gets an ordered array of dropdown keys.
   * @param {string} name name of the dropdown to retrieve
   * @returns {Array} array containing the dropdown keys, in order.
   */
  getAppListKeys(name) {
    if (!this.isReady()) {
      return [];
    }

    let languageData = this.getLanguageStrings();
    let fallbackData = this.getFallbackStrings();
    let appLists = languageData['app_list_strings'];
    let listValue = appLists?.[name];
    if (!listValue && fallbackData) {
      // fallback to englishStrings if one is not found in current current languageData object
      listValue = fallbackData['app_list_strings'][name];
    }

    return listValue ? Object.keys(appLists[name]) : [];
  }

  /**
   * Gets the value of the specified dropdown for the given key
   * @param {string} name name of the dropdown
   * @param {string} key key within the dropdown
   * @returns displayed value in a dropdown for the given key
   */
  getAppListValue(name, key) {
    let dropdown = this.getAppListObject(name);
    if (!dropdown || !key) {
      return '';
    }

    return dropdown[key];
  }

  /**
   * Given a date in string format, formats it to the user's selected locale
   * @param {string} dateString
   * @param {boolean} includeTime if true, the time is also returned
   * @returns string
   */
  formatDate(dateString, includeTime = false) {
    return includeTime
      ? new Date(dateString).toLocaleString()
      : new Date(dateString).toLocaleDateString();
  }

  /**
   * Given a date in string format, return the duration until the present date.
   * Only the highest unit of time will be displayed.
   * @param dateString
   * @returns string
   */
  dateToPresentDuration(dateString) {
    const interval = intervalToDuration({
      start: new Date(dateString),
      end: new Date(),
    });
    let customFormat;
    if (interval.years > 0) {
      customFormat = ['years'];
    } else if (interval.months > 0) {
      customFormat = ['months'];
    } else if (interval.days > 0) {
      customFormat = ['days'];
    } else if (interval.hours > 0) {
      customFormat = ['hours'];
    } else if (interval.minutes > 0) {
      customFormat = ['minutes'];
    } else if (interval.seconds > 0) {
      customFormat = ['seconds'];
    }
    return formatDuration(
      {
        years: interval.years,
        months: interval.months,
        weeks: interval.weeks,
        days: interval.days,
        hours: interval.hours,
        minutes: interval.minutes,
        seconds: interval.seconds,
      },
      { format: customFormat }
    );
  }
}

export default new Localization();
