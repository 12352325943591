import {
  ColorBlue400,
  ColorGray100,
  ColorGray900,
  ColorGreen400,
  ColorRed400,
} from '@maple-syrup/build/javascript/sugarColorPalette';
import { grey } from '@mui/material/colors';

export const theme = {
  typography: {
    fontFamily: 'Inter',
    button: {
      textTransform: 'none',
    },
    overline: {
      textTransform: 'none',
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: '1.245rem',
      letterSpacing: '0.011rem',
    },
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        bar: {
          backgroundColor: ColorGray100,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        sx: { color: grey[500] },
      },
    },
  },
  mixins: {},
  palette: {
    text: {
      hint: '#00000061',
    },
    mode: 'light',
    primary: {
      main: ColorBlue400,
    },
    secondary: {
      main: ColorGray900,
    },
    error: {
      main: ColorRed400,
    },
    success: {
      main: ColorGreen400,
    },
    info: {
      main: grey[100],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      reading: 700,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
  // appBar's default zIndex is 1200, but lightGalleryJS uses a zIndex of 1082 and we want the appBar to be hidden
  // when the lightbox is open.
  zIndex: {
    appBar: 1000,
  },
};
