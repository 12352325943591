import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Drawer as MuiDrawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  ClickAwayListener,
  Backdrop,
  Tooltip,
} from '@mui/material';
import { sidebarDefs } from '@kiosk/metadata/AdminConfig/Sidebar/configMetadata';
import localization from '@kiosk/i18n/localization';
import CustomNotistackSnackbar from '@kiosk/components/CustomNotistackSnackbar';
import SugarIcon from '@kiosk/components/SugarIcon';
import {
  ColorBlue400,
  ColorGray100,
  ColorWhite,
} from '@maple-syrup/build/javascript/sugarColorPalette';
import { supportedModules } from '@kiosk/metadata/base/baseMetadata';
import { selectUnsavedChanges } from '@kiosk/redux/slices/appConfig/appConfigSlice';

const SUPPORT_URL = 'https://support.sugarcrm.com';
const SUGAR_URL = 'https://sugarcrm.com';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  borderRight: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidth,
});

const closedMixin = (theme) => ({
  borderRight: 0,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 60,
});

const DrawerHeader = styled('div')(({ theme, open }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: 0,
  height: 48,
  [theme.breakpoints.up('sm')]: {
    minHeight: 48,
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '& .MuiDrawer-paperAnchorDockedLeft': {
    backgroundColor: ColorBlue400,
  },
}));

const AdminSidebar = () => {
  const [open, setOpen] = useState(false);

  const unsavedChanges = useSelector(selectUnsavedChanges);
  const { enqueueSnackbar } = useSnackbar();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Backdrop
        open={open}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      />
      <ClickAwayListener onClickAway={handleDrawerClose}>
        <Drawer variant='permanent' open={open}>
          <DrawerHeader open={open}>
            <IconButton
              aria-label='open drawer'
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              sx={{
                borderRadius: 0,
                color: ColorWhite,
                minHeight: 48,
                px: '20px',
                py: 0,
                width: '100%',
              }}>
              <SugarIcon
                name='hamburger-lg'
                sx={{
                  alignItems: 'center',
                  color: ColorWhite,
                  display: 'flex',
                  height: 48,
                  width: '100%',
                }}
              />
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ backgroundColor: ColorGray100 }} />
          <List sx={{ flexGrow: 1 }}>
            {sidebarDefs.map((def) => (
              <Link
                // Uncomment this when navigation between Admin pages is sorted out.
                // to={def.path}
                // // Reload the document when the link is clicked if we are not already in admin config service
                // // This ensures new admin service related context is loaded in and refreshed properly
                // reloadDocument={
                //   !window.location.pathname.split('/').includes('admin')
                // }
                // component={RouterLink}
                href={def.path}
                underline='none'
                key={def.name}
                onClick={(event) => {
                  if (unsavedChanges) {
                    event.preventDefault();
                    enqueueSnackbar(
                      localization.get('LBL_KIOSK_UNSAVED_CHANGES'),
                      {
                        content: (key, message) => (
                          <CustomNotistackSnackbar
                            id={key}
                            message={message}
                            variant={'error'}
                          />
                        ),
                      }
                    );
                  }
                }}>
                <Tooltip
                  title={localization.get(
                    def.label,
                    supportedModules.adminConfig
                  )}
                  placement='right'>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        minHeight: 48,
                        p: 0,
                      }}>
                      <SugarIcon
                        name={def.iconName}
                        size='16'
                        sx={{
                          alignItems: 'center',
                          color: ColorWhite,
                          display: 'flex',
                          height: 48,
                          justifyContent: 'center',
                          width: '3.75rem',
                        }}
                      />
                      <ListItemText
                        primaryTypographyProps={{ fontSize: '0.875rem' }}
                        primary={localization.get(
                          def.label,
                          supportedModules.adminConfig
                        )}
                        sx={{
                          fontSize: 15,
                          color: ColorWhite,
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              </Link>
            ))}
          </List>
          <Divider sx={{ backgroundColor: ColorGray100 }} />
          <List>
            <ListItem key={'Help'} disablePadding>
              <Tooltip title='Help' placement='right'>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    minHeight: 48,
                    p: 0,
                  }}
                  href={SUPPORT_URL}
                  target='_blank'>
                  <SugarIcon
                    name='help-lg'
                    size='16'
                    sx={{
                      alignItems: 'center',
                      color: ColorWhite,
                      display: 'flex',
                      height: 48,
                      justifyContent: 'center',
                      width: '3.75rem',
                    }}
                  />
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '0.875rem' }}
                    primary={'Help'}
                    sx={{
                      color: ColorWhite,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <ListItem key={'Sugarcrm'} disablePadding sx={{ display: 'block' }}>
              <Tooltip title={'SugarCRM'} placement='right'>
                <ListItemButton
                  sx={{
                    display: 'flex',
                    minHeight: 48,
                    p: 0,
                  }}
                  href={SUGAR_URL}
                  target='_blank'>
                  <SugarIcon
                    name='sugar-logo-16'
                    size='16'
                    sx={{
                      alignItems: 'center',
                      color: ColorWhite,
                      display: 'flex',
                      height: 48,
                      justifyContent: 'center',
                      width: '3.75rem',
                    }}
                  />
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '0.875rem' }}
                    primary={'SugarCRM'}
                    sx={{ color: ColorWhite, opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Drawer>
      </ClickAwayListener>
    </Box>
  );
};

export default AdminSidebar;
