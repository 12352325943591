import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentAuthenticatedUser } from '@kiosk/api/AuthenticatedUserApi';
import getRequestUrl from '@kiosk/utils/getRequestUrl';
import getInvitationOrPwReset from '@kiosk/utils/getInvitationOrPwReset';
import { status } from '@kiosk/redux/constants';
import skipStandardAuthFlow from '@kiosk/utils/skipStandardAuthFlow';
import { getUserDetails } from '@kiosk/api/UserManagementApi';

const initialState = {
  user: null,
  isAuthenticated: false,
  status: status.IDLE,
  error: null,
  crmUserDetails: null,
};

export const getAuthUser = createAsyncThunk('auth/getAuthUser', async () => {
  if (skipStandardAuthFlow()) {
    return {
      isUserAuthenticated: false,
      user: null,
    };
  } else {
    return await getCurrentAuthenticatedUser();
  }
});

export const getCrmUserDetails = createAsyncThunk(
  'auth/getCrmUserDetails',
  async (uid) => {
    return await getUserDetails(uid);
  }
);

// Make these method use dynamic variables for server address
export const authRedirects = {
  async login() {
    window.location.href = getRequestUrl('/rest/login');
  },

  async logout() {
    window.location.href = getRequestUrl('/rest/logout');
  },

  async acceptInvitationOrPwReset() {
    window.location.href = getInvitationOrPwReset('/rest/login/invitation');
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateCrmUserDetails(state, action) {
      const { userDetails } = action.payload;
      state.crmUserDetails = userDetails;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAuthUser.pending, (state, action) => {
        state.status = status.PENDING;
      })
      .addCase(getAuthUser.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        if (state.isAuthenticated) {
          state.user = action.payload.user;
        }
        state.status = status.FULFILLED;
      })
      .addCase(getAuthUser.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.error = action.error.message;
        state.status = status.FAILED;
      })
      .addCase(getCrmUserDetails.fulfilled, (state, action) => {
        state.crmUserDetails = action.payload;
      })
      .addCase(getCrmUserDetails.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;
export const selectAuthStatus = (state) => state.auth.status;
export const selectCrmUserDetails = (state) => state.auth.crmUserDetails;

export const { updateCrmUserDetails } = authSlice.actions;

export default authSlice.reducer;
