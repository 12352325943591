import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from '@kiosk/redux/slices/auth/authSlice';
import appConfigReducer from '@kiosk/redux/slices/appConfig/appConfigSlice';
import userPreferencesReducer from '@kiosk/redux/slices/userPreferences/userPreferencesSlice';
import breadcrumbsReducer from '@kiosk/redux/slices/breadcrumbs/breadcrumbsSlice';
import listsContextReducer from '@kiosk/redux/slices/listsContext/listsContext';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  auth: authReducer,
  appConfig: appConfigReducer,
  userPreferences: userPreferencesReducer,
  breadcrumbs: breadcrumbsReducer,
  listsContext: listsContextReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
};
