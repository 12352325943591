import localization from '@kiosk/i18n/localization';

export const firstTimeLoginModalBoxDefs = {
  title_label: 'LBL_KIOSK_FIRST_TIME_LOGIN_MODAL_TITLE',
  description_label: 'LBL_KIOSK_FIRST_TIME_LOGIN_MODAL_DESCRIPTION',
};

export const firstTimeLoginModalMetadata = [
  {
    field_name: 'given_name',
    field_label: 'LBL_KIOSK_FIRST_NAME',
    type: 'text',
    readonly: false,
    rules: {
      required: localization.get('LBL_KIOSK_FIRST_NAME_REQUIRED'),
    },
    options: {
      maxLength: 150,
    }
  },
  {
    field_name: 'family_name',
    field_label: 'LBL_KIOSK_LAST_NAME',
    type: 'text',
    readonly: false,
    rules: {
      required: localization.get('LBL_KIOSK_LAST_NAME_REQUIRED'),
    },
    options: {
      maxLength: 149,
    }
  },
];
