/**
 * Grabs the query parameter from the current URL by key name.
 * @param {string} key The key for the wanted query param value.
 * @returns string
 */
const getQueryParam = key => {
  let queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(key)) {
    return queryParams.get(key);
  }
  return '';
};

export default getQueryParam;