import { kFetch } from '@kiosk/api/ApiHelper';
import {
  TENANT_API_V1_BASE,
  TENANT_API_V1_VERSION,
} from '@kiosk/config/ApiConfig';

/**
 * GET endpoint to retrieve tenant details
 * @param {object} params
 * @returns response
 */
export const getTenant = async (params = { host: window.location.host }) => {
  try {
    const res = await kFetch.get(
      `/${TENANT_API_V1_BASE}/${TENANT_API_V1_VERSION}/lookup`,
      params
    );

    return res?.data?.data || null;
  } catch (err) {
    throw new Error(err.message, { cause: err });
  }
};
